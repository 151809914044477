<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="12" md="6">
        <div>
          <Perusahaan/>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <div>
          <Cabang/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Perusahaan from '@/components/Perusahaan';
  import Cabang from '@/components/Cabang';
  export default {
    components: {
      Perusahaan, Cabang, 
    },
    data: () => ({
    //
    }),
  };
</script>